import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"
// import Tickets from "../components/tickets"
import SpeakerCard from "../components/speakerCard"

import layoutStyle from "../components/layout.module.css"

const IndexPage = () => (
	<Layout
		meta={{
			fullTitle: "Accento 2021 — Dev/Java/JavaScript",
			description:
				"Accento is an amazing Java/JavaScript/Ops conference that takes place in Karlsruhe on September 28th/29th 2021.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<Snippet id="landing-accento" />

		{/* TODO add keynote */}

		<div>
			<Snippet id="landing-talks-intro" />
			<div className={layoutStyle.cards}>
				{["holly-cummins", "venkat-subramaniam", "gunnar-morling", "peter-kroener" ,"rachel-andrew", "nicolas-frankel"].map(speaker => (
					<SpeakerCard
						key={speaker}
						speakerSlug={speaker}
						size="medium"
						linkImage
						linkName
						showName
						showTalks
						fillVertical
					/>
					))}
			</div>
			<Snippet id="landing-talks-outro" />
		</div>

		<Snippet id="landing-plan" />

		{/*
		<div>
			<Snippet id="landing-trainings-intro" />
			<div className={layoutStyle.cards}>
				{["venkat-subramaniam", "peter-kroener", "unkown"].map(speaker => (
					<SpeakerCard
						key={speaker}
						speakerSlug={speaker}
						size="medium"
						linkImage
						linkName
						showName
						showTrainings
						fillVertical
					/>
					))}
			</div>
			<Snippet id="landing-trainings-outro" />
		</div>

		<Tickets />
		*/}

		{/* <Snippet id="landing-partner" /> */}
		<Snippet id="landing-contact" />
	</Layout>
)

export default IndexPage
